import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-info-comp-card',
  templateUrl: './info-comp-card.component.html',
  styleUrls: ['./info-comp-card.component.scss']
})
export class InfoCompCardComponent implements OnInit {

  _text: string

  @Input() set text(value: string){
    this._text = value;
  }
  @Input() editable = true;
  @Output() textChanged = new EventEmitter<string>();

  keypress = false;

  constructor() { }

  ngOnInit() {
  }

  saveCommentaire() {
    this.keypress = false;
    this.textChanged.emit(this._text);
  }

}

import {Adresse} from './adresse';
import {Contact} from './contact';

export class Concessionnaire {
    id: number;
    nom: string;
    statut: boolean;
    contact: Contact = new Contact();
    adresse: Adresse = new Adresse();
    // revendeurs: Revendeur[];
    nbrrevendeur: number;
    nbrrevendeurinactif: number;
    messagecomplementaire: string;
    commentaire: string;
    password: string;
}

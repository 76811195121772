import {Component, forwardRef, OnInit, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, Observer, of} from "rxjs";
import {Technicien} from "../../../classes/technicien";
import {TechnicienService} from "../../../services/technicien.service";
import {map, switchMap} from "rxjs/operators";
import {TypeaheadMatch} from "ngx-bootstrap";

@Component({
  selector: 'app-select-technicien',
  templateUrl: './select-technicien.component.html',
  styleUrls: ['./select-technicien.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectTechnicienComponent), multi: true}
  ]
})
export class SelectTechnicienComponent implements OnInit {

  search: string;
  disabledSelect: boolean;
  suggestion$: Observable<Technicien[]>

  @Input() user;

  set value(val){
    if( val && val !== undefined && this.val !== val && val.contact){
      this.val = val;
      this.search = val.contact.nom;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  get value() {
    return this.val;
  }

  val: Technicien = new Technicien();

  constructor( private technicienService: TechnicienService ) { }

  ngOnInit() {

    if (this.user.rightLevel === 5) this.disabledSelect = true;
    else this.disabledSelect = false;

    this.suggestion$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search)
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.technicienService.getTechniciens(10, 1, query)
            .pipe(map(res => res.data))
        }
        return of([])
      })
    )
  }

  onSelect(event: TypeaheadMatch): void {
    this.value = event.item;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

}

import {Concessionnaire} from './concessionnaire';
import {Contact} from './contact';
import {Adresse} from './adresse';

interface RevendeurSaveFormat {
    nom: string,
    concessionnaire: number,
    contact: Contact,
    adresse: Adresse,
    statut: boolean,
    commentaire: string,
    password: string
}

export class Revendeur {
    id: number;
    nom: string;
    concessionnaire: Concessionnaire = new Concessionnaire();
    contact: Contact = new Contact();
    adresse: Adresse = new Adresse();
    statut: boolean;
    nbrboitier: number;
    nbrclient: number;
    dateajout: string;
    messagecomplementaire: string;
    commentaire: string;
    password: string;

    getSaveFormat () {
        const _revendeur: RevendeurSaveFormat = {
            nom: this.nom,
            concessionnaire: this.concessionnaire ? this.concessionnaire.id : null,
            contact: this.contact,
            adresse: this.adresse,
            statut: this.statut,
            commentaire: this.commentaire,
            password: this.password
        }
        return _revendeur
    }
}

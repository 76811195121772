import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Concessionnaire} from '../classes/concessionnaire';
import {map} from 'rxjs/operators';

interface Collection {
  data: Concessionnaire[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number,
    from: number,
    to: number,
    total: number
    last_page: number,
    per_page: number,
    nb_total: number,
    nb_actif: number,
    nb_inactif: number,
  };
}

interface Res {
  data: Concessionnaire;
}

@Injectable({
  providedIn: 'root'
})
export class ConcessionnaireService {

  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  getConcessionnaires(nbParPage: number = 10, page: number = 1, q: string = null): Observable<Collection> {
    let params = new HttpParams()
        .set('nb_par_page', nbParPage.toString())
        .set('page', page.toString());
    if (q) {
      params = params.set('q', q);
    }
    return this.http.get<Collection>(`${this.baseUrl}/concessionnaire`, {
      params
    });
  }

  getConcessionnaire(id: number): Observable<Concessionnaire> {
    return this.http.get<Res>(`${this.baseUrl}/concessionnaire/${id}`)
        .pipe(
            map(res => res.data)
        )
  }

  updateConcessionnaire(concessionnaire: Concessionnaire): Observable<Concessionnaire> {
    return this.http.put<Res>(`${this.baseUrl}/concessionnaire/${concessionnaire.id}`, concessionnaire)
        .pipe(
            map(res => res.data)
        )
  }

  storeConcessionnaire(concessionnaire: Concessionnaire): Observable<Concessionnaire> {
    return this.http.post<Res>(`${this.baseUrl}/concessionnaire`, concessionnaire)
        .pipe(
            map(res => res.data)
        )
  }

  getRevendeurs(concessionnaire: Concessionnaire): Observable<Collection> {
    return  this.http.get<Collection>(`${this.baseUrl}/concessionnaire/${concessionnaire.id}/revendeur`)
  }
}

import {Component, Input, OnInit} from '@angular/core';

interface Card {
  libelle: string,
  nombre: number,
  icon: string,
  classes: string
}


@Component({
  selector: 'app-list-header-with-user',
  templateUrl: './list-header-with-user.component.html',
  styleUrls: ['./list-header-with-user.component.scss']
})
export class ListHeaderWithUserComponent implements OnInit {

  @Input() title: string;
  @Input() user;
  @Input() addText: string;
  @Input() addLink: string;
  @Input() cards: Card[];

  constructor() { }

  ngOnInit() {
  }

}

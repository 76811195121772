import {Contact} from './contact';
import {Adresse} from './adresse';
import {Revendeur} from './revendeur';

interface TechnicienSaveFormat {
    contact: Contact,
    adresse: Adresse,
    statut: boolean,
    commentaire: string,
    revendeur: number,
    password: string
}

export class Technicien {
    id: number;
    contact: Contact = new Contact();
    adresse: Adresse = new Adresse();
    revendeur: Revendeur = new Revendeur();
    nbrintervention: number;
    statut: boolean;
    commentaire: string;
    password: string;

    getSaveFormat() {
        const _technicien: TechnicienSaveFormat = {
            contact: this.contact,
            adresse: this.adresse,
            statut: this.statut,
            commentaire: this.commentaire,
            revendeur: this.revendeur && this.revendeur.id ? this.revendeur.id : null,
            password: this.password
        }
        return _technicien;
    }
}

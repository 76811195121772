import { Injectable } from '@angular/core';
import {Technicien} from '../classes/technicien';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Revendeur} from "../classes/revendeur";

interface Collection {
  data: Technicien[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: any;
}
interface Res {
  data: Technicien;
}

@Injectable({
  providedIn: 'root'
})
export class TechnicienService {

  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  getTechniciens(nbParPage: number = 10, page: number = 1, q: string = null): Observable<Collection> {
    let params = new HttpParams()
        .set('nb_par_page', nbParPage.toString())
        .set('page', page.toString());
    if (q) {
      params = params.set('q', q);
    }
    return this.http.get<Collection>(`${this.baseUrl}/technicien`,{
      params
    });
  }

  getTechnicien(id: number): Observable<Technicien> {
    return this.http.get<Res>(`${this.baseUrl}/technicien/${id}`)
        .pipe(
            map(res => {
              const _tech = new Technicien();
              Object.assign(_tech, res.data);
              return _tech;
            })
        )
  }

  updateTechnicien(technicien: Technicien): Observable<Technicien> {
    const _technicien: any = technicien;
    _technicien.revendeur = technicien.revendeur ? technicien.revendeur.id : null;
    return this.http.put<Res>(`${this.baseUrl}/technicien/${technicien.id}`, technicien)
        .pipe(
            map(res => res.data)
        )
  }

  storeTechnicien(technicien: Technicien): Observable<Technicien> {
    return this.http.post<Res>(`${this.baseUrl}/technicien`, technicien.getSaveFormat()).pipe(
        map(res => res.data)
    )
  }
}

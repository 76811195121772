import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';

interface Card {
  libelle: string,
  nombre: number,
  icon: string,
  classes: string
}

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() addText: string;
  @Input() addLink: string;
  @Input() cards: Card[];

  constructor() { }

  ngOnInit() {
  }

}

type UserAble = {
  actif: boolean
  adresse_id: number
  commentaire: string
  created_at: Date
  email: string
  entreprise: string
  id: number
  nom_contact: string
  prenom_contact: string
  siret: null
  site_internet: string
  telephone1: string
  telephone2: string
  tva: null
  updated_at: Date
}

export class User {
  id: number;
  name: string;
  type: string = null;
  email: string;
  userable?: UserAble | null;

  get isAdmin() {
    return this.type === 'admin';
  }

  get rightLevel() {
    let level;
    switch (this.type) {
      case 'admin':
        level = 10;
        break;
      case 'concessionnaire':
        level = 8;
        break;
      case 'revendeur':
        level = 6;
        break;
      case 'technicien':
        level = 5;
        break;
      default:
        level = 1;
        break;
    }
    return level;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {User} from '../../classes/user';

const misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
  level: number;
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Tableau de bord',
    type: 'link',
    icontype: 'fas fa-tachometer-alt text-white',
    level: 1
  },
  {
    path: '/concessionnaire',
    title: 'Concessionnaires',
    type: 'link',
    icontype: 'fas fa-user-tie text-white',
    level: 10
  },
  {
    path: '/revendeur',
    title: 'Revendeurs',
    type: 'link',
    icontype: 'fas fa-user-tag text-white',
    level: 8
  },
  {
    path: '/technicien',
    title: 'Techniciens',
    type: 'link',
    icontype: 'fas fa-user-cog text-white',
    level: 6
  },
  {
    path: '/client',
    title: 'Clients',
    type: 'link',
    icontype: 'fas fa-users text-white',
    level: 5
  },
  {
    path: '/boitier',
    title: 'Boitiers',
    type: 'link',
    icontype: 'fas fa-digital-tachograph text-white',
    level: 1
  },
  // {
  //   path: '/statistiques',
  //   title: 'Statistiques',
  //   type: 'link',
  //   icontype: 'fas fa-chart-area text-white',
  //   level: 10
  // },
  {
    path: '/reglages',
    title: 'Réglages',
    type: 'link',
    icontype: 'fas fa-cogs text-white',
    level: 10
  },
  {
    path: '/information',
    title: 'Info administrateur',
    type: 'link',
    icontype: 'fas fa-info text-white',
    level: 10
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  @Input() title: string;
  @Input() isMobileResolution: boolean;
  @Output() closeMenu = new EventEmitter<boolean>();

  user: User = new User();
  nameUser: string;

  constructor(private router: Router,
              private authService: AuthService ) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
    this.authService.getUser().subscribe(user => {
      this.user = user;
      if (user.rightLevel !== 10 && user.userable) {
        this.nameUser = `${user.userable.prenom_contact} ${user.userable.nom_contact}`
      }
      this.menuItems = ROUTES.filter(menuItem => menuItem.level <= user.rightLevel);
      if (user.isAdmin) {
        this.menuItems = ROUTES;
      }
    })
  }

  onClose() {
    this.closeMenu.emit();
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  // minimizeSidebar() {
  //   const sidenavToggler = document.getElementsByClassName(
  //     'sidenav-toggler'
  //   )[0];
  //   const body = document.getElementsByTagName('body')[0];
  //   if (body.classList.contains('g-sidenav-pinned')) {
  //     misc.sidebar_mini_active = true;
  //   } else {
  //     misc.sidebar_mini_active = false;
  //   }
  //   if (misc.sidebar_mini_active === true) {
  //     body.classList.remove('g-sidenav-pinned');
  //     body.classList.add('g-sidenav-hidden');
  //     sidenavToggler.classList.remove('active');
  //     misc.sidebar_mini_active = false;
  //   } else {
  //     body.classList.add('g-sidenav-pinned');
  //     body.classList.remove('g-sidenav-hidden');
  //     sidenavToggler.classList.add('active');
  //     misc.sidebar_mini_active = true;
  //   }
  // }
}

import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Observer, of} from 'rxjs';
import {Client} from '../../../classes/client';
import {ClientService} from '../../../services/client.service';
import {map, switchMap} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectClientComponent), multi: true}
  ]
})
export class SelectClientComponent implements OnInit,ControlValueAccessor {

  search: string;
  suggestion$: Observable<Client[]>

  set value(val){
    if( val && this.val !== val){
      this.val = val;
      this.search = val.nom;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  get value() {
    return this.val;
  }
  val: Client = new Client();

  constructor( private clientService: ClientService ) { }

  ngOnInit() {
    this.suggestion$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search)
    }).pipe(
        switchMap((query: string) => {
          if (query) {
            return this.clientService.getClients(10, 1, query)
                .pipe(map(res => res.data))
          }
          return of([])
        })
    )
  }

  onSelect(event: TypeaheadMatch): void {
    this.value = event.item;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

}

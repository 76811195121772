import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

interface Info {
  libelle: string,
  value: string,
  class: string
}

@Component({
  selector: 'app-info-gen-card',
  templateUrl: './info-gen-card.component.html',
  styleUrls: ['./info-gen-card.component.scss']
})

export class InfoGenCardComponent implements OnInit {

  @Input() infos: Info[];
  @Output() edit = new EventEmitter<boolean>()
  @Input() editable = true;

  constructor() { }

  ngOnInit() {
  }

  editBoitier() {
    this.edit.emit(true);
  }
}

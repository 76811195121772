import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-date-activation',
  templateUrl: './date-activation.component.html',
  styleUrls: ['./date-activation.component.scss'],
  providers: [DatePipe,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateActivationComponent), multi: true}
  ]
})
export class DateActivationComponent implements ControlValueAccessor {
  @Input() row: object;
  @Output() onUpdateBoitier = new EventEmitter<object>();

  isShowEdit = true;

  constructor(private datePipe: DatePipe,) { }

  set value(val){
    if( val !== undefined && this.val !== val){
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  get value() {
    return this.val;
  }

  val= '';

  onChange: any = () => {}
  onTouch: any = () => {}

  writeValue(value: any){
    this.value = value;
  }

  registerOnChange(fn: any){
    this.onChange = fn;
  }

  registerOnTouched(fn: any){
    this.onTouch = fn;
  }

  updateBoitier(obj: any) {
    obj.dateActivation = new Date(obj.dateActivation);
    obj.dateActivation.setHours(12)
    this.isShowEdit = true;
    this.onUpdateBoitier.emit(obj);
  }

  toggleIsShowEdit() {
    this.isShowEdit = false;
  }

}

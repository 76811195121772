import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
// import {CreationComponent} from "./../pages/clients/creation/creation.component";

@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextComponent), multi: true}
    ]
})
export class TextComponent implements ControlValueAccessor {

    @Input() inputId: string;
    @Input() libelle: string;
    @Input() placeholder: string;


    constructor() { }

    set value(val){
        if( val !== undefined && this.val !== val){
            this.val = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }
    get value() {
        return this.val;
    }
    val= '';

    onChange: any = () => {}
    onTouch: any = () => {}

    writeValue(value: any){
        this.value = value;
    }

    registerOnChange(fn: any){
        this.onChange = fn;
    }

    registerOnTouched(fn: any){
        this.onTouch = fn;
    }

}

import {Component, forwardRef, OnInit} from '@angular/core';
import {Observable, Observer, of} from 'rxjs';
import {Concessionnaire} from '../../../classes/concessionnaire';
import {Revendeur} from '../../../classes/revendeur';
import {RevendeurService} from '../../../services/revendeur.service';
import {map, switchMap} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-select-revendeur',
  templateUrl: './select-revendeur.component.html',
  styleUrls: ['./select-revendeur.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectRevendeurComponent), multi: true}
  ]
})
export class SelectRevendeurComponent implements OnInit {

  search: string;
  suggestion$: Observable<Revendeur[]>

  set value(val){
    if( val && val !== undefined && this.val !== val){
      this.val = val;
      this.search = val.nom;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  get value() {
    return this.val;
  }
  val: Revendeur = new Revendeur();

  constructor( private revendeurService: RevendeurService ) { }

  ngOnInit() {
    this.suggestion$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search)
    }).pipe(
        switchMap((query: string) => {
          if (query) {
            return this.revendeurService.getRevendeurs(10, 1, query)
                .pipe(map(res => res.data))
          }
          return of([])
        })
    )
  }

  onSelect(event: TypeaheadMatch): void {
    this.value = event.item;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { VectorMapComponent1 } from './vector-map/vector-map.component';

import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DxVectorMapModule } from 'devextreme-angular';
import {BsDropdownModule, TypeaheadModule} from 'ngx-bootstrap';
import { AdresseCardComponent } from './adresse-card/adresse-card.component';
import { InfoCompCardComponent } from './info-comp-card/info-comp-card.component';
import { InfoGenCardComponent } from './info-gen-card/info-gen-card.component';
import {FormsModule} from '@angular/forms';
import { ListHeaderComponent } from './list-header/list-header.component';
import { DetailHeaderComponent } from './detail-header/detail-header.component';
import { TextComponent } from './form/text/text.component';
import { StatutComponent } from './form/statut/statut.component';
import { AdresseComponent } from './form/adresse/adresse.component';
import { CreationPageComponent } from './creation-page/creation-page.component';
import { SelectConcessionnaireComponent } from './form/select-concessionnaire/select-concessionnaire.component';
import { SelectRevendeurComponent } from './form/select-revendeur/select-revendeur.component';
import { SelectClientComponent } from './form/select-client/select-client.component';
import { SelectTechnicienComponent } from './form/select-technicien/select-technicien.component';
import { ListHeaderWithUserComponent } from './list-header-with-user/list-header-with-user.component';
import { ListHeaderInformationComponent } from './list-header-information/list-header-information.component';
import { SelectClientInfoComponent } from './form/select-client-info/select-client-info.component';
import { ConnexionDelaisComponent } from './form/connexion-delais/connexion-delais.component';
import { DateActivationComponent } from './form/date-activation/date-activation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CollapseModule.forRoot(),
        DxVectorMapModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FormsModule,
        TypeaheadModule
    ],
  declarations: [
    FooterComponent,
    VectorMapComponent1,
    NavbarComponent,
    SidebarComponent,
    AdresseCardComponent,
    InfoCompCardComponent,
    InfoGenCardComponent,
    ListHeaderComponent,
    DetailHeaderComponent,
    TextComponent,
    StatutComponent,
    AdresseComponent,
    CreationPageComponent,
    SelectConcessionnaireComponent,
    SelectRevendeurComponent,
    SelectClientComponent,
    SelectTechnicienComponent,
    ListHeaderWithUserComponent,
    ListHeaderInformationComponent,
    SelectClientInfoComponent,
    ConnexionDelaisComponent,
    DateActivationComponent
  ],
    exports: [
        FooterComponent,
        VectorMapComponent1,
        NavbarComponent,
        SidebarComponent,
        AdresseCardComponent,
        InfoCompCardComponent,
        InfoGenCardComponent,
        ListHeaderComponent,
        DetailHeaderComponent,
        TextComponent,
        StatutComponent,
        AdresseComponent,
        CreationPageComponent,
        SelectConcessionnaireComponent,
        SelectRevendeurComponent,
        SelectClientComponent,
        SelectTechnicienComponent,
        ListHeaderWithUserComponent,
        ListHeaderInformationComponent,
        SelectClientInfoComponent,
        ConnexionDelaisComponent,
        DateActivationComponent
    ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ComponentsModule {}

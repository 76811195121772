import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable(
    { providedIn: 'root' }
)

export class AuthInterceptor implements HttpInterceptor {
    constructor( private authService: AuthService, private router: Router ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getToken();

        if (token) {
            const authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token)
            });

            return next.handle(authReq).pipe(
                catchError( err => {
                    if (err.status === 401) {
                        this.router.navigate(['/login']);
                    }
                    return throwError(err);
                })
            );
        } else {
            this.router.navigate(['/login']);
        }
        return next.handle(req);
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-creation-page',
  templateUrl: './creation-page.component.html',
  styleUrls: ['./creation-page.component.scss']
})
export class CreationPageComponent implements OnInit {

  @Input() title: string;
  @Output() save = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  onSave() {
    this.save.emit();
  }

  onCancel() {
    this.cancel.emit();
  }

}

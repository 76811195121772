export class Contact {
    id: number;
    nom: string;
    prenom: string;
    loginEmail: string;
    email: string;
    motdepasse: string;
    tel1: string;
    tel2: string;
    siteInternet: string;
    siret: string;
    numTva: string;
}

import {Component, forwardRef, OnInit} from '@angular/core';
import {Observable, Observer, of} from 'rxjs';
import {Concessionnaire} from '../../../classes/concessionnaire';
import {ConcessionnaireService} from '../../../services/concessionnaire.service';
import {map, switchMap} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-select-concessionnaire',
  templateUrl: './select-concessionnaire.component.html',
  styleUrls: ['./select-concessionnaire.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectConcessionnaireComponent), multi: true}
  ]
})
export class SelectConcessionnaireComponent implements OnInit,ControlValueAccessor {

  search: string;
  suggestion$: Observable<Concessionnaire[]>

  set value(val){
    if( val && val !== undefined && this.val !== val){
      this.val = val;
      this.search = val.nom;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  get value() {
    return this.val;
  }
  val: Concessionnaire = new Concessionnaire();

  constructor( private concessionnaireService: ConcessionnaireService ) { }

  ngOnInit() {
    this.suggestion$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search)
    }).pipe(
        switchMap((query: string) => {
          if (query) {
            return this.concessionnaireService.getConcessionnaires(10, 1, query)
                .pipe(map(res => res.data))
          }
          return of([])
        })
    )
  }

  onSelect(event: TypeaheadMatch): void {
    this.value = event.item;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onChange: any = () => {}
  onTouch: any = () => {}
}

import {Contact} from './contact';
import {Adresse} from './adresse';
import {Revendeur} from './revendeur';

interface ClientSaveFormat {
    nom: string,
    contratMaintenance: boolean,
    contact: Contact,
    adresse: Adresse,
    statut: boolean,
    commentaire: string,
    revendeur: number,
    password: string
}

export class Client {
    id: number;
    nom: string;
    contratMaintenance: boolean;
    contact: Contact = new Contact();
    adresse: Adresse = new Adresse();
    revendeur: Revendeur = new Revendeur();
    statut: boolean;
    message: string;
    commentaire: string;
    password: string;

    // renvoit l'objet client formatter pour l'update ou le store dans l'api
    
    getSaveFormat () {
        const _client: ClientSaveFormat = {
            nom: this.nom,
            contratMaintenance: this.contratMaintenance,
            contact: this.contact,
            adresse: this.adresse,
            statut: this.statut,
            commentaire: this.commentaire,
            revendeur: this.revendeur && this.revendeur.id ? this.revendeur.id : null,
            password: this.password
        }
        return _client;
    }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Client} from '../classes/client';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Boitier} from '../classes/boitier';

interface Collection {
    data: Client[];
    links: {
        first: string;
        last: string;
        prev: string;
        next: string;
    };
    meta: any;
}

interface Res {
    data: Client;
}

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getClients(nbParPage: number = 10, page: number = 1, q: string = null): Observable<Collection> {
        let params = new HttpParams()
            .set('nb_par_page', nbParPage.toString())
            .set('page', page.toString());
        if (q) {
            params = params.set('q', q);
        }
        return this.http.get<Collection>(`${this.baseUrl}/client`, {
            params
        });
    }

    getClient(id: number, nbParPage: number = 10, page: number = 1, q: string = null): Observable<Client> {
        let params = new HttpParams()
            .set('nb_par_page', nbParPage.toString())
            .set('page', page.toString());
        if (q) {
            params = params.set('q', q);
        }
        return this.http.get<Res>(`${this.baseUrl}/client/${id}`, {params})
            .pipe(
                map(res => res.data)
            )
    }

    updateClient(client: Client): Observable<Client> {
        const _client: any = client;
        _client.revendeur = client.revendeur ? client.revendeur.id : null;
        return this.http.put<Res>(`${this.baseUrl}/client/${client.id}`, client)
            .pipe(
                map(res => {
                    // const _client = new Client();
                    Object.assign(_client, res.data)
                    return _client;
                })
            )
    }

    storeClient(client: Client): Observable<Client> {
        return this.http.post<Res>(`${this.baseUrl}/client`, client.getSaveFormat())
            .pipe(
                map(res => {
                    const _client = new Client();
                    Object.assign(_client, res.data)
                    return _client;
                })
            )
    }

    getBoitiers(client: Client): Observable<Boitier[]> {
        return this.http.get<any>(`${this.baseUrl}/client/${client.id}/boitier`)
            .pipe(
                map( res => res.data)
            )
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
// import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {mod} from 'ngx-bootstrap/chronos/utils';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [ 
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(mod => mod.DashboardModule)
      },
      {
        path: 'concessionnaire',
        loadChildren: () => import('./pages/concessionnaires/concessionnaires.module').then(mod => mod.ConcessionnairesModule)
      },
      {
        path: 'revendeur',
        loadChildren: () => import('./pages/revendeurs/revendeurs.module').then(mod => mod.RevendeursModule)
      },
      {
        path: 'technicien',
        loadChildren: () => import('./pages/techniciens/techniciens.module').then(mod => mod.TechniciensModule)
      },
      {
        path: 'client',
        loadChildren: () => import('./pages/clients/clients.module').then(mod => mod.ClientsModule)
      },
      {
        path: 'boitier',
        loadChildren: () => import('./pages/boitiers/boitiers.module').then(mod => mod.BoitiersModule)
      },
      {
        path: 'statistiques',
        loadChildren: () => import('./pages/statistiques/statistiques.module').then(mod => mod.StatistiquesModule)
      },
      {
        path: 'reglages',
        loadChildren: () => import('./pages/reglages/reglages.module').then(mod => mod.ReglagesModule)
      },
      {
        path: 'information',
        loadChildren: () => import('./pages/information/information.module').then(mod => mod.InformationModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(mod => mod.AuthLayoutModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import {Revendeur} from '../classes/revendeur';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Boitier} from '../classes/boitier';

interface Collection {
  data: Revendeur[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: any;
}

interface Res {
  data: Revendeur;
}

@Injectable({
  providedIn: 'root'
})
export class RevendeurService {

  baseUrl = environment.apiUrl;

  constructor( private http: HttpClient ) { }

  getRevendeurs(nbParPage: number = 10, page: number = 1, q: string = null): Observable<Collection> {
    let params = new HttpParams()
        .set('nb_par_page', nbParPage.toString())
        .set('page', page.toString());
    if (q) {
      params = params.set('q', q);
    }
    return this.http.get<Collection>(`${this.baseUrl}/revendeur`, {
      params
    });
  }

  getRevendeur(id: number): Observable<Revendeur> {
    return this.http.get<Res>(`${this.baseUrl}/revendeur/${id}`)
        .pipe(
            map(res => res.data)
        )
  }

  updateRevendeur(revendeur: Revendeur): Observable<Revendeur>{
    const _revendeur: any = revendeur;
    _revendeur.concessionnaire = revendeur.concessionnaire ? revendeur.concessionnaire.id : null;
    return this.http.put<Res>(`${this.baseUrl}/revendeur/${revendeur.id}`, revendeur)
        .pipe(
            map(res => res.data)
        )
  }

  storeRevendeur(revendeur: Revendeur): Observable<Revendeur> {
    return this.http.post<Res>(`${this.baseUrl}/revendeur`, revendeur.getSaveFormat())
        .pipe(
            map(res => {
              const _revendeur = new Revendeur();
              Object.assign(_revendeur, res.data)
              return _revendeur;
            })
        )
  }

  getBoitiers(revendeur: Revendeur): Observable<Boitier[]> {
    return this.http.get<any>(`${this.baseUrl}/revendeur/${revendeur.id}/boitier`)
        .pipe(
            map(res => res.data)
        )
  }
}

import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Adresse} from '../../../classes/adresse';

declare const google: any;

@Component({
    selector: 'app-adresse',
    templateUrl: './adresse.component.html',
    styleUrls: ['./adresse.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdresseComponent), multi: true}
    ]
})
export class AdresseComponent implements ControlValueAccessor, AfterViewInit {

    @Input() inputId: string;
    @Input() libelle: string;
    @Input() placeholder: string;
    @ViewChild('element') element: ElementRef;

    constructor() {
    }

    set value(adresse: Adresse) {
        if (adresse !== undefined && this.val !== adresse) {
            this.val = adresse;
            this.onChange(adresse);
            this.onTouch(adresse);
        }
    }

    get value() {
        return this.val;
    }

    val: Adresse = new Adresse();

    onChange: any = () => {
    }
    onTouch: any = () => {
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    initAutocomplete() {
        // Create the autocomplete object, restricting the search predictions to
        // geographical location types.
        const autocomplete = new google.maps.places.Autocomplete(this.element.nativeElement, {types: ['geocode']});

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components.
        autocomplete.setFields(['address_component', 'geometry', 'icon', 'name']);

        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            const componentElement = {
                street_number: 'streetNumber',
                route: 'route',
                administrative_area_level_1: 'administrativeAreaLevel1',
                administrative_area_level_2: 'administrativeAreaLevel2',
                postal_code: 'postalCode',
                country: 'country',
                locality: 'locality'
            };

            for (const [key, val] of Object.entries(componentElement)) {
                const value = place.address_components.find(component => {
                    return component.types.find(type => type === key);
                });
                this.val[val] = value !== undefined ? value.short_name : '';
            }
            this.val.location = place.geometry.location;
            this.val.viewport = place.geometry.viewport;
        });
    }

    printAdresse() {
        if (!this.val) {
            return '';
        }
        let _adresse = '';


         _adresse += this.val.streetNumber ? this.val.streetNumber + ' ' : '';

        _adresse += this.val.route ? this.val.route : '';

        if (_adresse !== '') {
            _adresse += ', ';
        }

        _adresse += this.val.postalCode ? this.val.postalCode + ' ' : '';
        _adresse += this.val.locality ? this.val.locality : '';
        return _adresse;
    }

  ngAfterViewInit(): void {
      this.initAutocomplete()
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-header-information',
  templateUrl: './list-header-information.component.html',
  styleUrls: ['./list-header-information.component.scss']
})
export class ListHeaderInformationComponent implements OnInit {
  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }
}
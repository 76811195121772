export class Adresse {
    streetNumber: number = null;
    route: string = null;
    locality: string = null;
    administrativeAreaLevel1: string = null;
    administrativeAreaLevel2: string = null;
    postalCode: string = null;
    country: string = null;
    location: {
        lat: number,
        lng: number
    };
    viewport: object;
}

import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-statut',
  templateUrl: './statut.component.html',
  styleUrls: ['./statut.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StatutComponent), multi: true }
  ]
})
export class StatutComponent implements ControlValueAccessor {

  @Input() inputId: string;
  @Input() libelle: string;

  constructor() { }

  set value(val){
    if( val !== undefined && this.val !== val){
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  get value() {
    return this.val;
  }
  val= '';

  onChange: any = () => {}
  onTouch: any = () => {}

  writeValue(value: any){
    this.value = value;
  }

  registerOnChange(fn: any){
    this.onChange = fn;
  }

  registerOnTouched(fn: any){
    this.onTouch = fn;
  }
}

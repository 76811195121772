import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {tap, map} from 'rxjs/operators';
import {User} from '../classes/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  };

  public login(username: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/login`, {
      email: username,
      password,
      device_name: 'angular-spa'
    }, this.httpOptions)
        .pipe(
            tap(res => {
              localStorage.setItem('token', res.token);
            })
        );
  }

  public getToken() {
    return localStorage.getItem('token')
  }

  logout(): void {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
  }

  getUser(): Observable<User> {
    if (sessionStorage.getItem('user')) {
      const _user = new User();
      Object.assign(_user, JSON.parse(sessionStorage.getItem('user')));
      return of(_user);
    }
    return this.http.get<User>(`${this.apiUrl}/user`).pipe(
        tap(res => sessionStorage.setItem('user', JSON.stringify(res))),
        map(res => {
          console.log("User", res)
          const user = new User();
          Object.assign(user, res);
          return user;
        })
    );
  }
}

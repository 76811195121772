import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {Adresse} from '../../classes/adresse';

declare const google: any;

interface Location {
  lat: number,
  lng: number
}

@Component({
  selector: 'app-adresse-card',
  templateUrl: './adresse-card.component.html',
  styleUrls: ['./adresse-card.component.scss']
})

export class AdresseCardComponent implements OnInit, OnChanges {

  map: any;
  marker: any;

  @Input() adresse: Adresse;
  _adresse: Adresse = new Adresse();

  constructor() { }

  ngOnInit() {
    this.initMap();
  }

  initMap() {
    this.map = new google.maps.Map(document.getElementById('map'), {zoom: 10});
    if (this._adresse && this._adresse.location) {
      this.map.setCenter(this._adresse.location);
      this.marker = new google.maps.Marker({position: this._adresse.location, map: this.map});
    }
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
    for (const propName of Object.keys(changes) ) {
      const changedProp = changes[propName];
      if (propName === 'adresse') {
        this._adresse = changedProp.currentValue
        this.initMap();
      }
    }
  }

  printAdresse() {
    let adresse$ = '';
    if (this._adresse === undefined) {
      return '';
    }
    adresse$ += this._adresse.streetNumber ? this._adresse.streetNumber + ' ' : '';
    adresse$ += this._adresse.route ? this._adresse.route : '';

    if (adresse$ !== '') {
      adresse$ += ', ';
    }

    adresse$ += this._adresse.postalCode ? this._adresse.postalCode + ' ' : '';
    adresse$ += this._adresse.locality ? this._adresse.locality : '';
    return adresse$;
  }

}
